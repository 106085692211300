<template>
    <v-layout
        mt-5
        justify-center>
        <v-flex
            xs12
            sm10
            md8
            lg5
            pt-5
            mt-5>
            <v-card
                class="wrap-content gray-icons"
                light>
                <v-alert
                    :value="Object.keys(error).length"
                    type="error"
                    dismissible
                    transition="fade-transition"
                    class="mt-4 mx-4">
                    <span
                        :value="error"
                        v-html="error" />
                </v-alert>

                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <v-toolbar-title class="light-blue--text title-block">
                        Two-Factor Challenge
                    </v-toolbar-title>
                </v-toolbar>

                <v-card-text>
                    <v-container
                        text-center
                        fluid
                        grid-list-md>
                        <v-form
                            id="two-factor-authentication"
                            class="text-xs-center mb-4"
                            @submit.prevent="submitTwoFaCode">
                            <label>Please enter your authentication code to login.</label>
                            <v-text-field
                                v-model="code"
                                label="code"
                                required
                                class="pt-4 px-5" />
                            <v-checkbox
                                v-model="rememberDevice"
                                class="styled-checkbox ml-5 mb-4"
                                color="primary"
                                label="Remember me for 7 days" />
                            <div class="form-button">
                                <v-btn
                                    flat
                                    large
                                    class="blue-btn mt-4"
                                    color="white"
                                    type="submit"
                                    form="two-factor-authentication"
                                    :disabled="!code">
                                    Submit
                                </v-btn>
                            </div>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            code: '',
            error: '',
            rememberDevice: false,
        };
    },
    computed: {
        ...mapState({
            isLoggedIn: (state) => state.user.isLoggedIn,
            tempLoginDetails: (state) => state.user.tempLoginDetails,
        }),
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push(this.userHomepage);
        }
    },
    methods: {
        async submitTwoFaCode() {
            this.error = null;

            try {
                const { email, password } = this.tempLoginDetails;

                const response = await this.$store.dispatch('handleTwoFaChallenge', {
                    email,
                    password,
                    code: this.code,
                    rememberDevice: this.rememberDevice,
                });

                if (response.error) {
                    this.error = response.error;
                    return;
                }

                const data = response.data;
                if (!data) {
                    this.error = 'Invalid response from server';
                    return;
                }

                await this.$store.dispatch('completeLogin', { data, init: true });

            } catch (e) {
                this.error = 'An error occurred during the 2FA challenge. Please try again.';
            }
        }
    }
};
</script>
